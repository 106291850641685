import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrPromoteModuleEditReferralCodeView',
  computed: {
    ...mapState('promote', ['referralCodes'])
  }
})
export default class GtrPromoteModuleEditReferralCodeView extends Vue {
  data () {
    return {
      submitting: false,
      referralCode: {}
    }
  }

  async mounted () {
    await this.fetchReferralCodes()
    const referralCodes = this.$store.state.promote.referralCodes
    if (referralCodes.length > 0) {
      const referralCode = referralCodes.find((refCode: any) => refCode.uuid === this.$route.params.referral_code_uuid)
      if (referralCode) {
        this.$data.referralCode = referralCode
      }
    }
  }

  private async fetchReferralCodes () {
    try {
      this.$data.loading = true
      let type = 'REGISTRATION'
      if (this.promoteModule === 'leads') {
        type = 'LRORDERS'
      }
      await this.$store.dispatch('promote/getReferralCodes', { event_uuid: this.$route.params.event_uuid, type })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  get promoteModule () {
    return this.$route?.meta?.module || 'register'
  }

  get promoteRoute () {
    if (this.promoteModule === 'leads') {
      return 'level-two.modules.leads.promote'
    }
    return 'level-two.modules.registration.promote'
  }

  async submit () {
    const form = (this.$refs.editReferralCodeForm as HTMLFormElement)
    if (form.validate()) {
      try {
        this.$data.submitting = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          referral_code_uuid: this.$data.referralCode.uuid,
          data: {
            name: this.$data.referralCode.name,
            description: this.$data.referralCode.description,
            type: 'REGISTRATION'
          }
        }
        if (this.promoteModule === 'leads') {
          payload.data.type = 'LRORDERS'
        }
        await this.$store.dispatch('promote/editReferralCode', payload)
        Container.get(Notification).success('Referral code successfully updated.')
        this.$router.push({
          name: this.promoteRoute
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }
  }
}
